import { ProductRelatedVariation } from '@activebrands/core-web/types/product';

const sortRelatedVariations = (variations: ProductRelatedVariation[]) =>
    variations.sort((a, b) => {
        if (a.uri && b.uri) {
            return a.uri.localeCompare(b.uri);
        }

        return 0;
    });

export default sortRelatedVariations;
