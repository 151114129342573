import { ColorThumbnailType, ProductRelatedVariation } from '@activebrands/core-web/types/product';

const createRelatedVariation = (
    related: Record<string, any>,
    marketId?: string,
    productsMap?: Record<string, any>
): ProductRelatedVariation => {
    // @todo: Refactor this like transformProductCardMiniData source

    // The data looks different from Centra and Algolia
    const colorObj = related.color || related.custom_attributes?.attr_color || related?.colorName || {};
    const colorImageUrl = colorObj?.image?.url || colorObj?.image || colorObj.imageUrl;
    const variantThumbnailUrl = related.custom_attributes?.attr_variant_thumbnail_url;
    const forceOnline = related.custom_attributes?.attr_force_online_value === '1';

    // Product Data
    let onSale = related.price?.onSale;

    // Variant Data
    if (!onSale && productsMap) {
        const productData = productsMap[related.id];
        if (productData) {
            onSale = productData.price?.onSale;
        }
    }

    // Variant Data
    let inStock = related.inStock;

    // Product Data
    if (typeof inStock === 'undefined') {
        inStock = !!related.stock?.inStock;
    }

    // Not implemented yet
    // Algolia Data
    if (typeof inStock === 'undefined' && marketId) {
        inStock = !!related.stock_by_marketplace?.[marketId];
    }

    // Rules for colorThumbnail
    // 1. Variant thumbnail image
    // 2. First image in related.media that contains 'Product_1' or 'product_1' in the URL
    // 3. First image in related.media that contains 'Model_1' or 'model_1' in the URL
    // 4. Fallback to the first image in related.media
    let colorThumbnailUrl: string | undefined;
    let colorThumbnailType: ColorThumbnailType | undefined;

    if (variantThumbnailUrl) {
        colorThumbnailUrl = variantThumbnailUrl;
        colorThumbnailType = 'variant';
    } else if (related.media?.length > 0) {
        for (const media of related.media) {
            const url = media.sizes?.original?.url;
            const testUrl = url?.toLowerCase();

            if (url && testUrl.includes('model_1')) {
                colorThumbnailUrl = url;
                colorThumbnailType = 'model';
            }

            // Product_1 has priority over Model_1
            // Override the colorThumbnailUrl and break if we find a product image
            if (url && testUrl.includes('product_1')) {
                colorThumbnailUrl = url;
                colorThumbnailType = 'product';
                break;
            }
        }
        if (!colorThumbnailUrl) {
            colorThumbnailUrl = related.media[0].sizes?.original?.url;
            colorThumbnailType = 'fallback';
        }
    }

    return {
        colorHex: colorObj.hex,
        colorImageUrl,
        colorName: colorObj.name,
        colorThumbnailType,
        colorThumbnailUrl,
        forceOnline,
        id: related.id,
        inStock,
        name: related.name,
        onSale,
        sku: related.sku,
        uri: related.uri,
    };
};

export default createRelatedVariation;
