import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import Paragraph from 'components/text/Paragraph';

export const Wrapper = styled('div', {
    display: 'flex',
    alignItems: 'flex-start',
});

const ArticleCardEnd = ({ publishedAt }) =>
    publishedAt ? (
        <Wrapper>
            <Paragraph $style={{ color: 'var(--color-text-subtle)' }} fontKeys="Miscellaneous/12_100">
                {publishedAt}
            </Paragraph>
        </Wrapper>
    ) : null;

ArticleCardEnd.propTypes = {
    publishedAt: PropTypes.string,
};

export default ArticleCardEnd;
