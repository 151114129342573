import parseProductPrice from '@activebrands/core-web/utils/product/parse-product-price';
import objectKeysToCamelCase from '@grebban/utils/object/keysToCamelCase';

/**
 * Facets from Algolia is transformed into a string after a special template. This function reverts that into a object with the same data.
 * @example
 * // algoliaFacetDataExtraction('Red|red|/foo/bar.jpg|000000|1')
 * // returns { name: 'Red', internalName: 'red', imageSrc: '/foo/bar.jpg', hexCode: '000000' }
 * @returns {object}
 */
export const algoliaFacetDataExtraction = (data: string) => {
    const value: Record<string, any> = {};

    if (!data) {
        return value;
    }

    const [name, internalName, imageSrc, hexCode] = data.split('|');

    if (name) {
        value.name = name;
    }

    if (internalName) {
        value.internalName = internalName;
    }

    if (imageSrc) {
        value.imageSrc = imageSrc;
    }

    if (hexCode) {
        value.hexCode = `#${hexCode}`;
    }

    return value;
};

/**
 * Extracts data from the prices-value supplied from Algolia
 * @param priceFacet
 * @param pricelistId
 * @param marketId
 * @returns
 */
export const algoliaFacetPriceExtraction = (priceFacet: Record<string, any>, pricelistId: string, marketId: string) => {
    const pricelist = priceFacet?.[pricelistId]?.find(priceItem => priceItem.markets.includes(marketId));

    if (pricelist && pricelist.markets.includes(marketId)) {
        return parseProductPrice(objectKeysToCamelCase(pricelist.price));
    }

    return undefined;
};
