import PropTypes from 'prop-types';
import useSearchQuery from 'queries/useSearchQuery';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import Heading from 'components/text/Heading';

const InnerWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    marginBottom: '24px',
    flexWrap: 'wrap',
});

const SuggestedSearches = ({ $style = {}, suggestedItems = [] }) => {
    const [state, setState] = useStateContext();
    const [css] = useStyletron();
    const { headerConfigContent } = useSearchQuery();
    const { suggestedLabel } = headerConfigContent;
    let items;

    items = suggestedItems?.map(item => ({
        id: item.objectID,
        label: item.query,
    }));

    return (
        <div className={css({ ...$style })}>
            {suggestedLabel && (
                <Heading
                    $style={{
                        marginBottom: '12px',
                        color: 'var(--color-text-subtle)',
                    }}
                    as="h4"
                    fontKeys="Miscellaneous/12_100"
                >
                    {suggestedLabel}
                </Heading>
            )}
            <InnerWrapper>
                {items.map(item => {
                    return (
                        <ThemeButton
                            key={item.id}
                            size="sm"
                            theme="default"
                            onClick={event => setState(event.target.textContent)}
                        >
                            {item.label}
                        </ThemeButton>
                    );
                })}
            </InnerWrapper>
        </div>
    );
};

SuggestedSearches.propTypes = {
    $style: PropTypes.object,
    suggestedItems: PropTypes.array,
};

export default SuggestedSearches;
