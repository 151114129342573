import { MultipleQueriesQuery, SearchOptions, SearchResponse } from '@algolia/client-search';
import algoliasearch from 'algoliasearch';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APP_ID as string;
const API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_KEY as string;
const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';

// Update Algolia standard types to support filters as array
export type Filters = string | (string | string[])[];

export interface Params extends Omit<SearchOptions, 'filters'> {
    filters: Filters;
}

export interface MultiQuery extends Omit<MultipleQueriesQuery, 'params'> {
    params?: Params;
}

/*
 * Helper functions
 */
const getClient = () => (typeof window !== 'undefined' ? algoliasearch(APPLICATION_ID, API_KEY) : undefined);
const addIndexPrefix = (indexName: string) => `${PREFIX}${indexName}`;

const reportError = (
    error: Record<string, any> | unknown = {
        name: 'ServerQuery',
        message: 'QUERYING ON SERVER, SHOULD NOT DO THIS',
        status: 403,
    }
) => {
    console.warn(error);
    return error;
};

// Transform the result
const handleResult = (result: SearchResponse) => {
    const cloneResult = JSON.parse(JSON.stringify(result));

    // Add hasMore variable to result
    if (cloneResult.page !== undefined) {
        cloneResult.hasMore = (cloneResult.page + 1) * cloneResult.hitsPerPage < cloneResult.nbHits;
    } else {
        cloneResult.hasMore = cloneResult.offset + cloneResult.length < cloneResult.nbHits;
    }

    // Count pages from 1 instead of 0
    if (cloneResult.page !== undefined) {
        cloneResult.page++;
    }

    return cloneResult;
};

// Transform search options
const handleParams = (params?: Params) => {
    if (params) {
        const cloneParams = JSON.parse(JSON.stringify(params));

        // Transform filters supplied as arrays to string-query instead
        if (cloneParams.filters !== undefined && Array.isArray(cloneParams.filters)) {
            // First level of the array will have AND relation
            cloneParams.filters = cloneParams.filters
                .map((filter: string | string[]) => {
                    // Second level of array will have OR relation
                    if (Array.isArray(filter)) {
                        return `(${filter.join(' OR ')})`;
                    }
                    return `(${filter})`;
                })
                .join(' AND ');
        }

        // Transform requested page
        if (cloneParams.page !== undefined) {
            cloneParams.page--;
        }

        cloneParams.clickAnalytics = true;

        return cloneParams;
    }
};

/*
 * Search a single indices
 */
// https://www.algolia.com/doc/api-reference/api-methods/search/
export const search = async (indexName: string, query: string, params?: Params) => {
    const client = getClient();
    if (!client) {
        return reportError();
    }

    try {
        const index = client.initIndex(addIndexPrefix(indexName));
        const response = await index.search(query, handleParams(params));

        return handleResult(response);
    } catch (e) {
        return reportError(e);
    }
};

/*
 * Search multiple indices or queries
 */
// https://www.algolia.com/doc/api-reference/api-methods/multiple-queries/
export const searchMultiQuery = async (queries: MultiQuery[]) => {
    // Clone queries
    let cloneQueries = JSON.parse(JSON.stringify(queries));

    // Update queries with prefix depending on process.env
    cloneQueries = cloneQueries.map((q: MultiQuery) => ({
        ...q,
        params: handleParams(q.params),
        indexName: addIndexPrefix(q.indexName),
    }));

    const client = getClient();
    if (!client) {
        return reportError();
    }

    try {
        const response = await client.multipleQueries(cloneQueries);
        return response.results.map(handleResult);
    } catch (e) {
        return reportError(e);
    }
};
