import { algoliaFacetPriceExtraction } from '@activebrands/core-web/utils/algolia-utils';
import createRelatedVariations from '@activebrands/core-web/utils/product/create-related-variations';
import transformProductPageViewMedia from '@activebrands/core-web/utils/product/transform-product-page-view-media';

const parseSearchItem = (searchItem: Record<string, any>, pricelistId?: string, marketId?: string) => {
    const type = searchItem.sku ? 'product' : searchItem.type;

    switch (type) {
        case 'product': {
            // Get price data and parse it
            const price =
                pricelistId && marketId
                    ? algoliaFacetPriceExtraction(searchItem.prices, pricelistId, marketId)
                    : undefined;

            // Create related variations (colors)
            const relatedVariations = createRelatedVariations(searchItem, marketId);

            return {
                id: searchItem.id,
                media: transformProductPageViewMedia({ images: searchItem.images, source: 'Algolia' }),
                name: searchItem.name,
                objectID: searchItem.objectID,
                color: searchItem.color?.name,
                price,
                uri: /^\d+$/.test(searchItem.teamstore_id)
                    ? `${process.env.REACT_APP_TEAMSTORE_PRODUCT_PATH}?${searchItem.uri?.replace(/[\/]|(\?v=)/g, '')}`
                    : searchItem.uri,
                type: type,
                relatedVariations,
            };
        }

        // TODO: fix algolia setup
        case 'categoryPage':
            return {
                id: searchItem.id,
                label: searchItem.name,
                objectID: searchItem.objectID,
                tag: searchItem.breadcrumbs[0].name,
                to: searchItem.uri,
            };

        // TODO: fix algolia setup
        case 'articlePage':
            return {
                excerpt: searchItem.entryText,
                id: searchItem.id,
                imageSrc: searchItem.previewImage.filename, // TODO: get image from correct datapoint when we have algolia
                label: searchItem.name,
                objectID: searchItem.objectID,
                publishedAt: searchItem.publishedAt?.split('T')[0].replaceAll('-', '.'),
                tag: 'tag',
                to: searchItem.uri,
                type: searchItem.type,
            };

        default:
            return searchItem;
    }
};

export default parseSearchItem;
