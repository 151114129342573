import { ProductRelatedVariation } from '@activebrands/core-web/types/product';
import createRelatedVariation from '@activebrands/core-web/utils/product/create-related-variation';
import sortRelatedVariations from '@activebrands/core-web/utils/sort/sort-related-variations';

// currentItemFirst is a boolean that is used to determine if
// we want to do anything specific with the first related variant in the array.
// It's set to false by default but in product listing page, we want to set the currentVariation at the top of the array,
// to be able to see it first in the list of related variations.

export const createRelatedVariations = (
    product: Record<string, any>,
    marketId?: string,
    currentItemFirst = false,
    productsMap?: Record<string, any>
) => {
    const currentVariation = createRelatedVariation(product, marketId, productsMap);
    let relatedVariations: ProductRelatedVariation[] = [currentVariation];

    // Get related variations from either Centra or Algolia
    const rawRelatedVariations = product?.related_variations || product?.relatedVariations;
    if (rawRelatedVariations?.length) {
        // Create related variations and filter to only show the ones that are in stock or has forceOnline.
        const createdRelatedVariations = rawRelatedVariations
            .map((variation: object) => createRelatedVariation(variation, marketId, productsMap))
            // Force online variants should never be filtered out
            .filter((r: ProductRelatedVariation) => r.inStock || r.forceOnline);

        if (currentItemFirst) {
            // Sort all related variations after uri and add current variant first. For PLP
            relatedVariations = [currentVariation, ...sortRelatedVariations(createdRelatedVariations)];
        } else {
            // Sort all related variations after uri. For PDP.
            relatedVariations = sortRelatedVariations([currentVariation, ...createdRelatedVariations]);
        }
    }

    return relatedVariations;
};

export default createRelatedVariations;
