import PropTypes from 'prop-types';
import SpinnerIcon from '@activebrands/core-web/components/icons/SpinnerIcon';
import { styled } from '@activebrands/core-web/libs/styletron';

const Loadin = styled('div', ({ $hide }) => ({
    display: $hide ? 'none' : 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: '1',
    flexDirection: 'column',
    minHeight: '300px',

    animationTimingFunction: 'ease',
    animationDuration: `1s`,
    animationName: {
        from: {
            opacity: '0',
        },
        to: {
            opacity: '1',
        },
    },
}));

const Title = styled('h2', {
    textShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important',
    textTransform: 'uppercase',
    lineHeight: '0.85',
    margin: '32px 0 16px',
});

const Muted = styled('p', {
    opacity: '0.6',
    margin: '0',
});

const Loading = ({ hide, hideText, ...rest }) => (
    <Loadin $hide={hide} {...rest}>
        <SpinnerIcon />
        {!hideText && <Title>Loading</Title>}
        {!hideText && <Muted>Your content is being loaded</Muted>}
    </Loadin>
);

Loading.propTypes = {
    hide: PropTypes.bool,
    hideText: PropTypes.bool,
};

Loading.defaultProps = {
    hide: false,
    hideText: true,
};

export default Loading;
